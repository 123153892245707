var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.visitorSupportData.ref, items: _vm.items }
      }),
      _vm.visitorSupportData.statusPlain == "draft"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _vm.visitorSupportData.statusPlain == "draft" &&
                  _vm.$can("edit_vsr")
                    ? _c(
                        "div",
                        { staticClass: "button-items" },
                        [
                          _vm.visitorSupportData.statusPlain == "draft"
                            ? _c(
                                "b-button",
                                { attrs: { variant: "primary" } },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-edit font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Modifier ")
                                ]
                              )
                            : _vm._e(),
                          _vm.visitorSupportData.statusPlain == "draft" &&
                          _vm.$can("approve_vsr")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.approvalModal",
                                      modifiers: { approvalModal: true }
                                    }
                                  ],
                                  attrs: { variant: "success" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check-double font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Approuver ")
                                ]
                              )
                            : _vm._e(),
                          _vm.visitorSupportData.statusPlain == "draft" &&
                          _vm.$can("reject_vsr")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.rejectionModal",
                                      modifiers: { rejectionModal: true }
                                    }
                                  ],
                                  attrs: { variant: "danger" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-block font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Rejeter ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      return _vm.fetchSingleTakeawayRequestData()
                    }
                  }
                },
                [
                  _c("h1", [
                    _c("i", {
                      staticClass: "bx bx-rotate-right",
                      staticStyle: { float: "right" }
                    })
                  ])
                ]
              ),
              _c("h4", { staticClass: "card-title mb-4" }, [_vm._v("Détails")]),
              _c("p", { attrs: { lass: "text-muted mb-4" } }, [
                _vm._v(" " + _vm._s(_vm.visitorSupportData.description) + " ")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Demandeur :")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.visitorSupportData.requestedBy))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Structure d'accueil :")]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.visitorSupportData.structure_type) +
                                " : " +
                                _vm._s(_vm.visitorSupportData.structure) +
                                " "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Imputation :")]),
                          _c("td", [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.visitorSupportData.analyic_account)
                              )
                            ])
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Motif de la demande :")]),
                          _c("td", [
                            _vm._v(
                              " " + _vm._s(_vm.visitorSupportData.reason) + " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Prestations :")]),
                          _c("td", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.visitorSupportData.prestations
                                )
                              }
                            })
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Justificatif :")]),
                          _c("td", [_vm._v("#")])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Date de demande :")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.visitorSupportData.requestDate))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Etat :")]),
                          _c("td", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.visitorSupportData.status)
                              }
                            })
                          ])
                        ]),
                        _vm.visitorSupportData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Rejeté par :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.visitorSupportData.rejectedBy)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.visitorSupportData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Date de rejet:")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.visitorSupportData.rejectionDate)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.visitorSupportData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Raison de rejet:")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.visitorSupportData.rejectionReason)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.visitorSupportData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Approuvée par :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.visitorSupportData.approvedBy)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.visitorSupportData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Approuvée Le :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.visitorSupportData.approvedAt)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.visitorSupportData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Bon de Livraison :")]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "ops.receptions.display",
                                          params: {
                                            uid:
                                              _vm.visitorSupportData.reception
                                                .uuid
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.visitorSupportData.reception.ref
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ]),
              _c("hr"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Visiteurs: ")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _c("h5", [
                    _vm._v(
                      "Entreprise: " +
                        _vm._s(_vm.visitorSupportData.companyName)
                    )
                  ]),
                  _c("p", { staticClass: "card-title-desc" }),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-striped mb-0" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Nom complet")]),
                          _c("th", [_vm._v("N° Pièce d'identité")]),
                          _c("th", [_vm._v("Type de prise en charge")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.visitorSupportData.entries, function(entry) {
                          return _c("tr", { key: entry.id }, [
                            _c("td", [_vm._v(_vm._s(entry.fullName))]),
                            _c("td", [
                              _vm._v(_vm._s(entry.identityDocYype) + " N°: "),
                              _c("b", [_vm._v(_vm._s(entry.identityDocNo))])
                            ]),
                            _c("td", [_vm._v(_vm._s(entry.supportType.name))])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("approval-modal", {
        attrs: {
          camps: _vm.campsList,
          requestData: _vm.visitorSupportData,
          modelUid: _vm.visitorSupportData.id,
          modelRef: _vm.visitorSupportData.ref
        }
      }),
      _c("rejection-modal", {
        attrs: {
          modelRef: _vm.visitorSupportData.ref,
          modelId: _vm.visitorSupportData.id,
          modelType: _vm.visitorSupportData.modelType
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }